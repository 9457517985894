<template>
  <div>
    <header-top-dashboard
      primary
      :custom-right-content="true"
      :show-back-button="true"
    >
    </header-top-dashboard>
    <v-container fluid class="pt-0 px-6 mt-n16">
      <v-row>
        <v-col class="mt-n3">
          <v-card class="card-shadow mb-6">
            <v-card-text class="card-padding">
              <v-form>
                <v-row>
                  <v-col cols="12" md="12" class="py-0">
                    <h3>Product Details</h3>
                    <v-divider style="margin-bottom: 15px"></v-divider>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Product Name</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Product Name"
                      v-model="product.name"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.product.name.$errors)
                      "
                      @blur="v$.product.name.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Product Group</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Product Group"
                      :items="productGroups"
                      item-text="name"
                      item-value="id"
                      v-model="product.productGroupId"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.product.status.$errors)
                      "
                      @blur="v$.product.status.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Product Status</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Product Status"
                      :items="productStatuses"
                      item-text="value"
                      item-value="key"
                      v-model="product.status"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.product.status.$errors)
                      "
                      @blur="v$.product.status.$touch"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Cost Price</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Cost Price"
                      v-model="product.costPrice"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.product.costPrice.$errors)
                      "
                      @blur="v$.product.costPrice.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Retail Price</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Retail Price"
                      v-model="product.retailPrice"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.product.retailPrice.$errors)
                      "
                      @blur="v$.product.retailPrice.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Manufacturer</label
                    >
                    <v-select
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Manufacturer"
                      :items="manufacturers"
                      item-text="name"
                      item-value="id"
                      v-model="product.manufacturerId"
                    >
                    </v-select>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Barcode</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Bar Code"
                      v-model="product.barcode"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Package Units</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Package Units"
                      v-model="product.packageUnits"
                      required
                      :error-messages="
                        getFieldValidationErrors(
                          v$.product.packageUnits.$errors
                        )
                      "
                      @blur="v$.product.packageUnits.$touch"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Unit Weight</label
                    >
                    <v-row>
                      <v-col cols="8">
                        <v-text-field
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          placeholder="Package Weight"
                          v-model="product.weight"
                          required
                          :error-messages="
                            getFieldValidationErrors(v$.product.weight.$errors)
                          "
                          @blur="v$.product.weight.$touch"
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="4">
                        <v-select
                          outlined
                          class="input-style font-size-input text-light-input placeholder-light"
                          placeholder="Measure"
                          :items="weightMeasures"
                          v-model="product.weightMeasure"
                          item-text="key"
                          item-value="value"
                          required
                          :error-messages="
                            getFieldValidationErrors(
                              v$.product.weightMeasure.$errors
                            )
                          "
                          @blur="v$.product.weightMeasure.$touch"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-col>

                  <v-col cols="12" md="4" class="py-0">
                    <label
                      for=""
                      class="label-color font-weight-600 mb-2 d-block"
                      >Stock on Hand</label
                    >
                    <v-text-field
                      outlined
                      class="input-style font-size-input text-light-input placeholder-light"
                      placeholder="Stock on Hand"
                      v-model="product.stockOnHand"
                      required
                      :error-messages="
                        getFieldValidationErrors(v$.product.stockOnHand.$errors)
                      "
                      @blur="v$.product.stockOnHand.$touch"
                    >
                    </v-text-field>
                  </v-col>
                </v-row>

                <v-btn
                  @click="submit"
                  elevation="0"
                  :ripple="false"
                  height="43"
                  :loading="submitButton.isLoading"
                  class="font-weight-600 text-capitalize btn-primary py-3 px-6 rounded-sm me-2"
                  color="#5e72e4"
                  >Create</v-btn
                >
              </v-form>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapStores } from "pinia";
import { useSessionStore } from "@/store/session";
import useVuelidate from "@vuelidate/core";
import { required, numeric } from "@vuelidate/validators";
import HeaderTopDashboard from "@/components/HeaderTopDashboard.vue";

export default {
  name: "products-create",
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    HeaderTopDashboard,
  },
  validations() {
    return {
      product: {
        name: { required },
        retailPrice: { required },
        costPrice: { required, numeric },
        // type: { required },
        packageUnits: { required, numeric },
        weight: { required, numeric },
        weightMeasure: { required },
        stockOnHand: { required, numeric },
        status: { required },
        productGroupId: { required },
      },
    };
  },
  props: {},
  data() {
    return {
      product: {
        id: 0,
        name: "",
        retailPrice: "",
        discountPrice: "",
        costPrice: "",
        type: "",
        barcode: "",
        packageUnits: "",
        weight: "",
        weightMeasure: "",
        stockOnHand: "",
        status: "",
        productGroupId: null,
        manufacturerId: null,
        images: {
          primary: {
            file: null,
            imageUrl: require("@/assets/img/placeholder-200x200.png"),
          },
          secondary: {
            file: null,
            imageUrl: require("@/assets/img/placeholder-200x200.png"),
          },
          tertiary: {
            file: null,
            imageUrl: require("@/assets/img/placeholder-200x200.png"),
          },
          quaternary: {
            file: null,
            imageUrl: require("@/assets/img/placeholder-200x200.png"),
          },
        },
      },
      productGroups: [],
      manufacturers: [],
      submitButton: {
        isLoading: false,
      },
    };
  },
  computed: {
    ...mapStores(useSessionStore),
  },
  methods: {
    getFieldValidationErrors(errors) {
      const messages = [];

      for (let i = 0; i < errors.length; i++) {
        messages.push(`${errors[i].$message}`);
      }

      return messages;
    },

    async submit() {
      const isFormCorrect = await this.v$.$validate();

      if (!isFormCorrect) {
        this.$notify.warning(
          "Please resolve the validation errors before proceeding!"
        );
        return;
      }

      this.submitButton.isLoading = true;

      const productBody = {
        name: this.product.name,
        retail_price: this.product.retailPrice,
        cost_price: this.product.costPrice,
        type: this.product.type,
        package_units: this.product.packageUnits,
        weight: `${this.product.weight}${this.product.weightMeasure}`,
        stock_on_hand: this.product.stockOnHand,
        status: this.product.status,
        product_group_id: this.product.productGroupId,
        manufacturer_id: this.product.manufacturerId,
      };

      if (this.product.barcode) {
        productBody.barcode = this.product.barcode;
      }

      this.$http
        .post("/products", productBody, this.sessionStore.getHttpConfig)
        .then(async (result) => {
          this.$notify.success("Product created successfully!");
          this.$router.push(`/products/edit/${result.data.data[0].id}`);
        })
        .catch((error) => {
          this.handleHttpError(
            error,
            "An error occurred while creating the product. Please try again later or contact support."
          );
        })
        .finally(() => {
          this.submitButton.isLoading = false;
        });
    },
  },
  mounted() {
    this.$http
      .get("/product-groups?size=100", this.sessionStore.getHttpConfig)
      .then((result) => {
        const productGroups = result.data.data;

        this.productGroups.splice(0);
        for (let i = 0; i < productGroups.length; i++) {
          this.productGroups.push(productGroups[i]);
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading the product groups. Please try again later or contact support."
        );
      });

    this.$http
      .get("/manufacturers?size=100", this.sessionStore.getHttpConfig)
      .then((result) => {
        const manufacturers = result.data.data;

        this.manufacturers.splice(0);
        for (let i = 0; i < manufacturers.length; i++) {
          this.manufacturers.push(manufacturers[i]);
        }
      })
      .catch((error) => {
        this.handleHttpError(
          error,
          "An error occurred while loading the manufacturers. Please try again later or contact support."
        );
      });
  },
};
</script>
